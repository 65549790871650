<template>
  <div class="m-index">
    <TopBar :type="1" :logo="logo" />
    <!-- <div class="service" v-if="bottomInfo.waiter_qrcode" @click="showCode = true">联系<br />客服</div> -->
    <!-- banner图&图标跳转 -->
    <div class="banner_cont" v-if="bannerList.length>0">
        <div class="swiper_cont">
            <swiper
                class="m-banner"
                ref="bannerSwiper"
                :options="swiperOptionsBanner"
                v-if="bannerList.length"
                >
                <swiper-slide
                    v-for="(item, index) in bannerList"
                    :key="item.image + '_' + index"
                >
                <img v-if="!item.link" class="banner-img" :src="item.image" />
                <a :href="item.link" v-else>
                  <img class="banner-img" :src="item.image" />
                </a>
                </swiper-slide>
                <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            </swiper>
        </div>
        <div class="menu_cont" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73">
            <ul class="flex">
                <a href="https://www.wjx.cn/vm/hiRVLgn.aspx#" target="_blank" rel="noopener noreferrer">
                <li>
                    <p class="icon"><img src="@/assets/m/newIndex/icon1.png" alt=""></p>
                    <p class="text">定制授课</p>
                </li>
                </a>
                <a href="https://npx.kskstudy.com/teacherList" target="_blank" rel="noopener noreferrer">
                <li>
                    <p class="icon"><img src="@/assets/m/newIndex/icon2.png" alt=""></p>
                    <p class="text">大国工匠</p>
                </li>
                </a>
                <a href="https://zgyxk.kskstudy.com/researchList" target="_blank" rel="noopener noreferrer">
                <li>
                    <p class="icon"><img src="@/assets/m/newIndex/icon3.png" alt=""></p>
                    <p class="text">企业研学</p>
                </li>
                </a>
                <a href="https://npx.kskstudy.com/brand/list?type=1" target="_blank" rel="noopener noreferrer">
                <li>
                    <p class="icon"><img src="@/assets/m/newIndex/icon4.png" alt=""></p>
                    <p class="text">更多专题</p>
                </li>
                </a>

                
            </ul>
        </div>
    </div>
    <!-- 直播 -->
    <div class="modal-content live" v-if="liveModule[0]">
      <div class="live-title">
        {{ liveModule[0].liveNow ? "正在直播" : "即将直播"
        }}<span class="time">{{ liveModule[0].live_date }}</span>
      </div>
      <div
        class="live-content"
        @click="$router.push('/live/' + liveModule[0].channel_no +'/'+ liveModule[0].id)"
      >
        <img class="live-img" :src="liveModule[0].mobile_image" />
        <div class="live-info">
          <p class="live-info-title vertical-ellipsis">
            {{ liveModule[0].name }}
          </p>
          <p class="live-info-lecturer">
            主讲人：{{ liveModule[0].teacher_name }}
          </p>
          <div class="live-btn" v-if="liveModule[0].liveNow">进入直播</div>
        </div>
      </div>
      <swiper
        class="live-list"
        ref="liveSwiper"
        :options="swiperOptionsLive"
        v-if="liveModule.length > 1"
      >
        <!-- v-if="item.id != liveModule[0].id" -->
        <swiper-slide v-for="item in liveModule" :key="item.id">
          <router-link class="list-info" :to="'/live/' + item.channel_no">
            <p class="list-info-title">
              {{ item.live_date
              }}<span class="list-info-lecturer"
                >主讲人：{{ item.teacher_name }}</span
              >
            </p>
            <p class="list-info-name">{{ item.name }}</p>
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
    <div class="container_cont_data">
        <!-- 创新思维与方法 -->
        <div class="cont_item cont1">
            <div class="title_cont_info flex">
                <p class="text">{{showText}}</p>
                <!-- <div class="more_btn flex" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73"  @click="goNewUrl('https://npx.kskstudy.com/')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon1.png" alt="">
                </div>
                <div class="more_btn flex" v-else  @click="$router.push('/courseList')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon1.png" alt="">
                </div> -->
            </div>
            <div class="tab_cont_list">
                <ul>
                   <li
                    class="cursor"
                    v-for="item in moduleList1"
                    :key="item.id"
                    :class="modalId1 == item.id ? 'active' : ''"
                    @click="changModal(item.id,'1')"
                    >
                    {{ item.name }}
                    </li>
                </ul>
            </div>
            <div class="course_list">
                <ul>
                    <li v-for="item in courseList1" :key="item.id" @click="goCourseDetail(item)">
                        <p class="img"><img :src="item.image" alt=""></p>
                        <p class="text overflow_e">{{ item.name }}</p>
                    </li>
                </ul>
            </div>
        </div>
         <!-- 大师来了 -->
        <div class="teacher_cont_box" v-if="teacherList.length>0">
            <div class="title_cont">
                <p class="title"><img src="@/assets/m/newIndex/text_icon.png" alt=""></p>
                <div v-if="type == 2" class="more_btn flex" @click="$router.push('/lecturer')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon2.png" alt="">
                </div>
            </div>
            <!-- <p class="tips_text">国家级工作室带头人入企指导</p> -->
            <div class="teacher_list_cont">
                <swiper
                    class="teacherSwiper"
                    ref="bannerSwiper"
                    :options="teacherOptions"
                    v-if="teacherList.length"
                    >
                    <swiper-slide
                        class="slide_item"
                        v-for="(item) in teacherList"
                        :key="item.id"
                    >
                        <p class="img">
                            <img :src="item.avatar" alt="">
                        </p>
                        <div class="cont_info">
                            <p class="name">{{item.name}}</p>
                            <p class="level">{{item.level}}</p>
                            <div class="desc overflow_3">{{item.introduce}}</div>
                        </div>
                        <!-- <a :href="item.link">
                        <img class="banner-img" :src="item.image" />
                        </a> -->
                    </swiper-slide>
                    <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                </swiper>
            </div>
        </div>
        <!-- 创新工作室-工作室解读 -->
        <div class="cont_item cont2" v-for="(item, index) in contDataInfo" :key="item.id">
            <div class="title_cont_info flex">
                <p class="text overflow_o">{{item.name}}</p>
                <div class="more_btn flex"  @click="$router.push('/activeList/' + item.id + '/' + item.name)">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon1.png" alt="">
                </div>
            </div>
            <div class="course_cont_list">
                <ul>
                    <li v-for="list in item.list" :key="list.id" @click="toActiveInfo(list)">
                        <p class="img"><img :src="list.image" alt=""></p>
                        <div class="info_cont">
                            <p class="name overflow_2">{{list.name}}</p>
                            <p class="time">{{list.created_at}}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="vert_box" v-if="advertList[index]">
                <div class="advert_image">
                <a :href="advertList[index].m_link">
                    <img :src="advertList[index].m_image" />
                </a>
                </div>
            </div>
        </div>
        <div class="cont_item cont1" v-if="moduleList2.length>0">
            <div class="title_cont_info flex">
                <p class="text" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73">创新思维与创新方法</p>
                <p class="text" v-else>专业能力提升培训课程</p>
                <div class="more_btn flex" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73"  @click="goNewUrl('https://npx.kskstudy.com/')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon1.png" alt="">
                </div>
                <div class="more_btn flex" v-else  @click="$router.push('/courseList')">
                    <span>更多</span>
                    <img src="@/assets/m/newIndex/right_icon1.png" alt="">
                </div>
            </div>
            <div class="tab_cont_list">
                <ul>
                   <li
                    class="cursor"
                    v-for="item in moduleList2"
                    :key="item.id"
                    :class="modalId2 == item.id ? 'active' : ''"
                    @click="changModal(item.id,'2')"
                    >
                    {{ item.name }}
                    </li>
                </ul>
            </div>
            <div class="course_list">
                <ul>
                    <li v-for="item in courseList2" :key="item.id" @click="goCourseDetail(item)">
                        <p class="img"><img :src="item.image" alt=""></p>
                        <p class="text overflow_e">{{ item.name }}</p>
                    </li>
                </ul>
            </div>
        </div>

       
         <a href="https://www.wjx.cn/vm/hiRVLgn.aspx#" target="_blank" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73">
            <div class="line_img">
                <img src="@/assets/m/newIndex/line_img1.png" alt="">
            </div>
        </a>
        <a href="https://www.wjx.cn/vm/hiRVLgn.aspx#" target="_blank" v-if="siteInfo&&siteInfo.id&&siteInfo.id==73">
            <div class="line_img last_img">
                <img src="@/assets/m/newIndex/line_img2.png" alt="">
            </div>
        </a>
    </div>

    <div class="deep_cont_box">
        <div class="cont_info">
            <div>
                <img src="@/assets/m/newIndex/phone_icon.png" alt="">
                <div class="text flex">
                    <p>热线电话：</p><span>400-0123-823</span>
                </div>
            </div>
            <div class="text">
               <p> 技术支持：中工科创</p>
            </div>
        </div>
        <div class="code_text">
            Copyright © 2015-{{new Date().getFullYear()}} kskstudy.com All Rights Reserved 京ICP备16012224号-3
        </div>
    </div>
    
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import TopBar from "@/m/common/topBar.vue";
export default {
  name: "Index",
  components: {
    TopBar,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      logo: "",
      
      bannerList: [],
      moduleList:[],//创新思维与创新方法 分类
      moduleList1:[],//创新思维与创新方法 分类
      moduleList2:[],//创新思维与创新方法 分类
      modalId1:'',
      modalId2:'',
      courseList1:[],//创新思维与创新方法
      courseList2:[],//创新思维与创新方法
      teacherList:[],//大师来了
      showTeacher:'',//当前显示讲师
      contDataInfo:[],


      //banner
      swiperOptionsBanner: {
        slidesPerView: 1, //显示slide的数量
        centeredSlides: true, //居中显示
        spaceBetween: 14, //间距
        loop: true, //循环
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        //setWrapperSize :true,  // Swiper使用flexbox布局(display: flex)，
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //是否可点击
        },
      },
      siteInfo:'',
        //   讲师轮播图
       teacherOptions:{
            slidesPerView: 1.2, //显示slide的数量
            centeredSlides: true, //居中显示
            spaceBetween: 14, //间距
            loop: true, //循环
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
            autoplay: {
            delay: 300000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
            },
       },
       //直播滚动
        swiperOptionsLive: {
            direction: "vertical", //竖向滚动
            loop: false, //循环
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
            autoplay: {
            delay: 2000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
            },
            loop: true,
            //setWrapperSize :true,  // Swiper使用flexbox布局(display: flex),
        },
        //讲师
        lecturerOptionsLive: {
            slidesPerView: 1.1, //显示slide的数量
            spaceBetween: 24, //间距
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
            autoplay: {
            delay: 2000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
            },
            loop: true,
        },
       showText:'安全管理培训',
       liveModule: [], //直播模块
       liveSelect: [],
       type: 1 // 1 劳动管理 2 黄河工匠学院
     
    };
  },
  metaInfo() {
    return {
      meta: [
        // { name: "keywords", content: this.setting.keywords },
        // { name: "description", content: this.setting.description },
      ],
    };
  },
  mounted() {
    if(window.location.host=='hhgjxy.kskstudy.com'){
        this.showText = '工匠人才培育'
        this.type = 2
    }else{
        this.showText = '安全管理培训'
        this.type = 1
    }
    this.siteInfo = localStorage.getItem('siteInfo')? JSON.parse(localStorage.getItem('siteInfo')):''
    this.getWebsiteInfo(); //获取网站信息
    this.getBanner();
    this.getModule(); //获取首页模块
    this.getLliveModule(); //获取直播课堂
    this.getAdvert(); //获取广告条
    this.websiteData = this.$store.state.websiteState;
    //微信登录
    if (this.$route.query.noncestr) {
      this.weLoin(this.$route.query.noncestr);
    }
  },
  methods: {
    ...mapMutations(["ShowMLoginModal", "ShowTipModal", "CloseTipModal"]), //登录弹窗

    //选择直播
    selectLive(data) {
      this.liveSelect = data;
    },
    getChannelNo() {
      this.$router.push(
        "/live/" + this.liveSelect.channel_no + "/" + this.liveSelect.id
      );
    },
    //获取直播课堂
    getLliveModule() {
      this.$axios.post(`/v1/home/liveModule`).then((res) => {
        let nowDta = Date.now() / 1000;

        for (let i = 0; i < res.data.length; i++) {
          if (nowDta > res.data[i].live_time && nowDta < res.data[i].end_time) {
            res.data[i].liveNow = true;
          } else {
            res.data[i].liveNow = false;
          }
        }

        this.liveModule = res.data;
        this.liveSelect = res.data[0];
      });
    },
    // 获取有数据的头部
    async getHeadData(moduleData) {
      this.modulelist = moduleData;
      this.moduleList1 = [];
      if (this.modulelist <= 0) {
        this.loadState = false;
        setTimeout(() => {
          this.loadState1 = false;
        }, 1000);
      }
      for (let i = 0; i < moduleData.length; i++) {
        let res = await this.$axios.post(`/v1/home/moduleData`, {
          id: moduleData[i].id,
        });

        for (let i = 0; i < this.modulelist.length; i++) {
          if (res.data.total) {
            for (let o = 0; o < res.data.data.length; o++) {
              if (res.data.data[o].module_id == this.modulelist[i].id) {
                if (this.modulelist[i].list) {
                  this.$set(this.modulelist[i].list, o, res.data.data[o]);
                } else {
                  this.$set(this.modulelist[i], "list", []);
                  this.$set(this.modulelist[i].list, o, res.data.data[o]);
                }
              }
            }
          } else {
            for (let o = 0; o < res.data.length; o++) {
              if (res.data[o].module_id == this.modulelist[i].id) {
                if (this.modulelist[i].list) {
                  this.$set(this.modulelist[i].list, o, res.data[o]);
                } else {
                  this.$set(this.modulelist[i], "list", []);
                  this.$set(this.modulelist[i].list, o, res.data[o]);
                }
              }
            }
          }
        }
      }
      this.moduleList1 = this.modulelist.filter((item) => item.list);
    },
    // 获取广告条
    getAdvert() {
      this.$axios
        .post(`/v1/home/advert`, {
          type: "1",
        })
        .then((res) => {
          if (res.code == 0) {
            this.advertList = res.data;
          }
        });
    },
    changModal(item,num){
      if(num==1){
        this.modalId1=item
      }else{
        this.modalId2 = item
      }
        this.getCourseList(item,num)
    },
    goCourseDetail(item){
        this.$router.push('/newRecordedInfo/'+item.data_id)
    },

    //跳转活动文章详情
    toActiveInfo(data){
      if(data.type===0){
        this.$router.push('/activeInfo/'+data.data_id);
      }else if(data.type===1){
        window.open(data.pc_link);
      }
    },
    goNewUrl(url){
        window.open(url)
    },
    // 获取banner图
    getBanner() {
      this.$axios
        .post(`/v1/home/banner`, {
          type: "2",
        })
        .then((res) => {
          if (res.data.length == 1) {
            this.swiperOptionsBanner.loop = false;
          }
          this.bannerList = res.data;
        });
    },
    //获取首页模块
    getModule() {
      this.$axios.post(`/v1/home/module`).then((res) => {
        if(res.code==0){
            let contBox3 = []
            res.data.forEach(el=>{
                if(el.type==1){
                    this.moduleList.push(el)
                }
                if(el.type==5){
                    this.getTeacher(el.id)
                }
                if(el.type==9){
                    contBox3.push(el)
                }
            })
          //  if(this.moduleList.length>6){
          //     this.moduleList1 = this.moduleList.slice(0,6)
          //     this.moduleList2 = this.moduleList.slice(6,this.moduleList.length)
          //     this.modalId2 = this.moduleList2[0].id
          //     this.getCourseList(this.modalId2,'2')
          //   }else{
              this.moduleList1 = this.moduleList
            // }
            this.modalId1 = this.moduleList1[0].id
            this.getCourseList(this.modalId1,'1')
            this.getModuleData(contBox3);
            this.getHeadData(this.moduleList1);
        }
       
      });
    },
    // 获取 创新思维与创新方法 列表
    getCourseList(id,num){
        this.$axios.post(`/v1/home/moduleData`,{id:id}).then(res=>{
           if(num==1){
              this.courseList1 = res.data.data
            }else{
              this.courseList2 = res.data.data
            }
        })
    },
    // 获取 大师来了 列表
    getTeacher(id){
        this.$axios.post(`/v1/home/moduleData`,{id:id}).then(res=>{
            let data = []
            if(res.data.length>4){
                data = res.data.splice(0,4)
            }else{
                data = res.data
            }
            data.forEach(el=>{
              el.course=[]
              el.honor=[]
            })
            this.teacherList = data
            this.getTeacherDetail(this.teacherList[0])
        })
    },
    getTeacherDetail(item){
      this.showTeacher = item
      this.$axios.post(`/v1/home/teacherTag`,{teacher_id:item.teacher_id}).then(res=>{
        if(res.code==0){
          this.showTeacher.course = res.data.course
          this.showTeacher.honor = res.data.honor
          console.log(this.showTeacher)
        }
      })
      
    },
    //获取各模块数据
    async getModuleData(moduleData) {
      this.contDataInfo = moduleData;

    //   if (this.contDataInfo <= 0) {
    //     this.loadState = false;
    //     setTimeout(() => {
    //       this.loadState1 = false;
    //     }, 1000);
    //   }
      for (let i = 0; i < moduleData.length; i++) {
        let res = await this.$axios.post(`/v1/home/moduleData`, {
          id: moduleData[i].id,
        });

        for (let i = 0; i < this.contDataInfo.length; i++) {
          if (res.data.total) {
            for (let o = 0; o < res.data.data.length; o++) {
              if (res.data.data[o].module_id == this.contDataInfo[i].id) {
                if (this.contDataInfo[i].list) {
                  this.$set(this.contDataInfo[i].list, o, res.data.data[o]);
                } else {
                  this.$set(this.contDataInfo[i], "list", []);
                  this.$set(this.contDataInfo[i].list, o, res.data.data[o]);
                }
              }
            }
          } else {
            for (let o = 0; o < res.data.length; o++) {
              if (res.data[o].module_id == this.contDataInfo[i].id) {
                if (this.contDataInfo[i].list) {
                  this.$set(this.contDataInfo[i].list, o, res.data[o]);
                } else {
                  this.$set(this.contDataInfo[i], "list", []);
                  this.$set(this.contDataInfo[i].list, o, res.data[o]);
                }
              }
            }
          }
        }
        this.loadState = false;
        setTimeout(() => {
          this.loadState1 = false;
        }, 1000);
        // this.loading.close();
      }
    },
    
    //获取网站信息
    getWebsiteInfo() {
      this.$axios.post(`/v1/websiteInfo`, { is_pc: 2 }).then((res) => {
        this.bottomInfo = res.data.site_info;
        localStorage.setItem("siteInfo", JSON.stringify(res.data.site_info));
        localStorage.setItem(
          "navigation",
          JSON.stringify(res.data.navigation)
        );
        this.logo = res.data.site_info.site_logo;
        this.menuList = res.data.navigation; //获取金刚区菜单
        document.getElementById("titleName").innerText =
          res.data.site_info.name;
        //  this.getMeat();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.m-index {
  background-color: #f7f7f7;
}
.banner_cont{
    background: #fff;
    box-sizing: border-box;
    padding: 28px 32px 50px;
    // 轮播图
    .swiper_cont{
        width: 100%;
        height: 272px;
        .m-banner{
            width: 100%;
            img{
                width: 100%;
                height: 272px;
            }
        }
    }
    .menu_cont{
        margin-top: 44px;
        ul{
            justify-content: center;
            a{
                flex: 1;
            }
            li{
                
                
                p.icon{
                    width: 96px;
                    height: 96px;
                    margin: 0 auto;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                p.text{
                    margin-top: 12px;
                    font-size: 28px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;
                }
            }
            li:nth-child(4n){
                margin-right: 0;
            }
        }
    }
}

.container_cont_data{
    margin-top: 20px;
    .cont_item{
      background: #fff;
      box-sizing: border-box;
      padding: 52px 32px;
      .title_cont_info{
        justify-content: space-between;
        p.text{
            font-size: 36px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #333333;
            flex: 1;
        }
        .more_btn{
            margin-left: 20px;
            span{
                font-size: 28px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #999999;
            }
            img{
                width: 28px;
                height: 32px;
            }
        }
      }
    }
    
    // 创新思维与方法
    .cont1{
        .tab_cont_list{
            margin-top: 32px;
            ul{
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                li{
                    // float: left;
                    flex: none;
                    height: 56px;
                    border-radius: 56px;
                    opacity: 1;
                    border: 2px solid #AAAAAA;
                    line-height: 56px;
                    padding: 0 32px;
                    margin-right: 24px;
                    font-size: 28px;

                    
                }
                li.active{
                    background: #036AE2;
                    border: 2px solid #036AE2;
                    color: #fff;
                }
                li:last-child{
                    margin-right: 0;
                }
            }
        }
        .course_list{
            margin-top: 18px;
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    width: 48%;
                    margin-right: 4%;
                    margin-bottom: 48px;
                    p.img{
                        width: 328px;
                        height: 178px;
                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 5px;
                        }
                    }
                    p.text{
                        font-size: 28px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 36px;
                        margin-top: 12px;
                    }
                }
                li:nth-child(2n){
                    margin-right: 0;
                }
            }
        }
    }

    // 大师来了
    .teacher_cont_box{
        background: url('~@/assets/m/newIndex/teacher_bgm.png') no-repeat;
        background-size: 100% 380px;
        margin-bottom: 80px;
        .title_cont{
            padding-top: 18px;
            position: relative;
            p.title{
                width: 232px;
                height: 60px;
                margin: 0 auto;
                img{
                    width: 100%;
                }
            }
            .more_btn{
                position: absolute;
                right: 32px;
                top: 22px;
                span{
                    font-size: 28px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
                img{
                    width: 28px;
                    height: 32px;
                }
            }
        }
        p.tips_text{
            font-size: 28px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 36px;
            text-align: center;
            margin-top: 24px;
        }
        .teacher_list_cont{
            margin-top: 32px;
            .teacherSwiper{
                .slide_item{
                    width: 630px;
                    height: 326px;
                    background: #FFFFFF;
                    box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.08);
                    border-radius: 24px 24px 24px 24px;
                    box-sizing: border-box;
                    padding: 40px;
                    display: flex;
                    p.img{
                        width: 194px;
                        height: 246px;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .cont_info{
                        flex: 1;
                        margin-left: 36px;
                        p.name{
                            font-size: 36px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            color: #3D3D3D;
                        }
                        p.level{
                            margin-top: 12px;
                            font-size: 24px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            color: #E79E0C;
                            background: #F9F1E6;
                            display: inline-block;
                            padding: 10px 20px;
                            border-radius: 40px;
                        }
                        div.desc{
                            margin-top: 12px;
                            font-size: 26px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                }
            }
        }
    }


    // 创新工作室
    .cont2{
        margin-bottom: 20px;
        .course_cont_list{
            margin-top: 36px;
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    display: flex;
                    border-bottom: 1px solid #EBEBEB;
                    padding-bottom: 42px;
                    margin-bottom: 38px;
                    width: 100%;
                    p.img{
                        width: 276px;
                        height: 152px;
                        border-radius: 8px 8px 8px 8px;
                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                        }
                    }
                    .info_cont{
                        flex: 1;
                        margin-left: 24px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        p.name{
                            font-size: 30px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                        }
                        p.time{
                            font-size: 24px;
                            font-family: Source Han Sans, Source Han Sans;
                            font-weight: 400;
                            color: #999999;
                            margin-top: 30px;
                        }
                    }
                }
                li:last-child{
                    border: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.line_img{
    img{
        width: 100%;
        display: block;
    }
}
.last_img{
    margin-top: -20px;
}

.deep_cont_box{
    width: 100%;
    height: 212px;
    background: #292F36;
    box-sizing: border-box;
    padding: 48px 40px 0;
    .cont_info{
        display: flex;
        justify-content: space-between;
        >div{
            display: flex;
            justify-content: space-between;
            font-size: 28px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            img{
                width: 40px;
                height: 40px;
            }
            span{
                color: #1E84FF;
                 opacity: 1;
            }

        }
        .text{
             p{
                opacity: .5;
             }
        }
    }
    .code_text{
        font-size: 24px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        text-align: center;
        margin-top: 28px;
        opacity: .5;
        line-height: 40px;
    }
}

::-webkit-scrollbar {
      width: 0;
    }
::-webkit-scrollbar {
      width: 0;
    }
    /* 隐藏 IE 和 Edge 浏览器的滚动条 */
    ::-ms-scrollbar {
      width: 0;
    }
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.overflow_o {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflow_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.overflow_3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.overflow_4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.modal-content {
  padding: 38px 28px;
  padding-right: 0;
  background-color: #fff;
  &.live {
    padding-right: 28px;
    padding-top: 44px;
    margin-bottom: 10px;
  }
  .modal-title {
    font-size: 36px;
    color: #333;
    font-weight: 600;
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .more {
      padding-right: 40px;
      font-size: 28px;
      color: #999;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 40px;
        height: 40px;
        background: url(~@/assets/arrows.png) no-repeat center center;
        background-size: 100%;
        transform: rotate(-90deg);
        position: absolute;
        top: 0;
        right: 0;
      }

      margin-right: 30px;
    }
  }
  .modal-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 0 10px 10px;
    &::-webkit-scrollbar {
      //隐藏滚动条
      display: none;
    }
    .modal-list-info {
      flex-shrink: 0;
      width: 386px;
      height: 454px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(0, 12, 40, 0.14);
      border-radius: 7px;
      overflow: hidden;
      margin-right: 28px;
      &.active {
        height: 360px;
      }
    }
    .list-info-img {
      width: 100%;
      height: 214px;
    }
    .list-info-content {
      padding: 10px 20px 0;
    }
    .info-content-title {
      height: 74px;
      font-size: 28px;
      color: #333;
      line-height: 37px;
    }
    .info-sub-title {
      font-size: 24px;
      color: #999;
      -webkit-line-clamp: 1;
    }
    .info-content-subtitle {
      font-size: 24px;
      color: #999;
    }
    .info-content-price {
      padding: 28px 4px 0;
      border-top: 1px solid #e5e5e5;
      margin-top: 27px;
      font-size: 30px;
      color: #cd1b14;
      font-weight: bold;
    }
  }
  &.lecturer {
    background-color: #f7f7f7;
    .lecturer-card {
      box-sizing: border-box;
      width: 100%;
      padding: 28px;
      background-color: #fff;
      overflow: hidden;
      position: relative;
    }
    .lecturer-img {
      width: 158px;
      height: 208px;
      border-radius: 8px;
      float: left;
      object-fit: cover;
    }
    .lecturer-content {
      width: 340px;
      float: left;
      margin-left: 20px;
    }
    .lecturer-title {
      display: flex;
      align-items: center;
      .marks {
        margin: 0 0 0 10px;
        .mark {
          padding: 8px;
          background: rgba(255, 195, 11, 0.14);
          font-size: 24px;
          color: #f5a623;
        }
      }
    }
    .lecturer-name {
      font-size: 28px;
      color: #333;
    }
    .lecturer-intro {
      padding-top: 20px;
      max-height: 70px;
      line-height: 35px;
      font-size: 26px;
      color: #999;
      //多行省略号
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .lecturer-btn {
      width: 128px;
      height: 42px;
      line-height: 42px;
      border: 1px solid #254ed4;
      border-radius: 6px;
      text-align: center;
      font-size: 24px;
      color: #254ed4;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
  &.xilie {
    padding-left: 0;
    padding-right: 0;
    .modal-title {
      padding-left: 28px;
    }
  }
  .xilie-list-info {
    padding: 28px;
    border-bottom: 1px solid #f5f5f5;
    .xilie-img {
      width: 308px;
      height: 200px;
      float: left;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 294px;
        height: 200px;
        background: linear-gradient(
          180deg,
          rgba(195, 204, 255, 0.14) 0%,
          rgba(125, 134, 168, 0.17) 45%,
          rgba(72, 82, 103, 0.3) 100%
        );
        box-shadow: 0px 1px 0px 0px #f5f5f5;
        border-radius: 6px;
        border-image: linear-gradient(
            180deg,
            rgba(159, 159, 159, 0),
            rgba(72, 82, 103, 0.07)
          )
          1 1;
        position: absolute;
        left: 0;
        right: 0;
        margin: 6px auto;
        z-index: 1;
      }
      &::after {
        content: "";
        display: block;
        width: 274px;
        height: 200px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(72, 82, 103, 0.13) 100%
        );
        box-shadow: 0px 1px 0px 0px #f5f5f5;
        border-radius: 6px;
        border-image: linear-gradient(
            180deg,
            rgba(159, 159, 159, 0),
            rgba(72, 82, 103, 0.06)
          )
          1 1;
        position: absolute;
        left: 0;
        right: 0;
        margin: 12px auto;
        z-index: 0;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
      }
    }
    .xilie-con {
      width: 356px;
      float: left;
      margin-left: 20px;
      position: relative;
    }
    .xilie-name {
      height: 80px;
      line-height: 40px;
      font-size: 28px;
      color: #333;
      //多行省略号
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .xilie-teachers {
      padding-top: 10px;
      font-size: 24px;
      color: #999;
    }
    .xilie-intro {
      font-size: 24px;
      color: #999;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal;
      word-wrap: break-word;
      max-height: 60px;
    }
    .xilie-price {
      padding-top: 28px;
      font-size: 32px;
      font-weight: bold;
      color: #cd1b14;
      .unit {
        font-size: 24px;
      }
    }
  }
  &.style_8 {
    padding-right: 28px;
  }
  .active-list {
    .active-info {
      box-sizing: border-box;
      padding: 27px 32px;
      box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
      display: flex;
      justify-content: space-between;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 20px;
    }
    .active-img {
      width: 289px;
      height: 163px;
      border-radius: 6px;
    }
    .active-con {
      width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .active-name {
      font-size: 32px;
      color: #333;
    }
    .active-sub-title {
      height: 36px;
      font-size: 14px;
      color: #999;
      line-height: 36px;
      -webkit-line-clamp: 1;
    }
  }
}
.live-title {
  height: 44px;
  padding-left: 50px;
  font-size: 32px;
  color: #f5a623;
  background: url(~@/assets/live-now-ico.png) no-repeat;
  background-size: 44px;
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  .time {
    padding: 2px 10px;
    background-color: #f5f5f5;
    font-size: 22px;
    color: #999;
    margin-left: 38px;
  }
}
.live-content {
  display: flex;
  // justify-content: space-between;
  .live-img {
    // width: 242px;
    // height: 180px;
    width: 164px;
    height: 200px;
    border-radius: 6.63px;
  }
  .live-info {
    width: 462px;
    height: 180px;
    position: relative;
    margin-left: 30px;
  }
  .live-info-title {
    font-size: 28px;
    color: #333;
    height: 76px;
    line-height: 38px;
  }
  .live-info-lecturer {
    font-size: 24px;
    color: #999;
    margin-top: 14px;
  }
  .live-btn {
    padding: 10px 14px 10px 44px;
    background: url(~@/assets/video-ico.png) no-repeat 10px center #f5a623;
    background-size: auto 28px;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 24px;
    color: #fff;
    text-align: center;
  }
}
.live-list {
  height: 154px;
  background-color: #f5f5f5;
  margin-top: 45px;
  .list-info {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 33px 20px 28px;
  }
  .list-info-title {
    padding-left: 44px;
    background: url(~@/assets/clock-ico.png) no-repeat left center;
    background-size: auto 24px;
    font-size: 22px;
    color: #999;
  }
  .list-info-lecturer {
    font-size: 24px;
    color: #999;
    margin-left: 28px;
  }
  .list-info-name {
    box-sizing: border-box;
    width: 490px;
    height: 45px;
    line-height: 45px;
    padding-left: 44px;
    margin-top: 24px;
    font-size: 28px;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 1px;
      height: 45px;
      background-color: #ccc;
      position: absolute;
      top: 0;
      left: 12px;
    }
  }
}
// 广告位
.vert_box {
  padding: 76px 0 0;
  background: #fff;
  margin-left: -28px;
  img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
}
</style>
